import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import './moments.css';
import imagearrowleft from './images/momentsarrowleft.svg';
import imagearrowright from './images/momentsarrowright.svg';

function Moments() {
    const [hoveredImage, setHoveredImage] = useState(null);
    const [momentsImages, setMomentsImages] = useState([]);
    const containerRef = useRef(null);

    const fetchMomentsImages = async () => {
        try {
            const response = await axios.get('https://yeahtrips.in/getthepictureperfectmoments');
            setMomentsImages(response.data);
        } catch (error) {
            console.error("Error fetching the moments images:", error);
        }
    };

    useEffect(() => {
        fetchMomentsImages();
    }, []);

    const scrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                left: -containerRef.current.offsetWidth / 2,
                behavior: 'smooth'
            });
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                left: containerRef.current.offsetWidth / 2,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className="perfect-moment">
            <div className="moment-element">
                <h1>Picture Perfect Moments 📸</h1>
            </div>

            <div className="momentscontainer" ref={containerRef} tabIndex="0">
                {momentsImages.map((image) => (
                    <img
                        key={image.id}
                        src={`https://admin.yeahtrips.in${image.image}`}
                        alt="Perfect moment"
                        className={hoveredImage === image.image ? "hovered" : "shrink"}
                        onMouseEnter={() => setHoveredImage(image.image)}
                        onMouseLeave={() => setHoveredImage(null)}
                    />
                ))}
            </div>

            <div className="momentsarrows">

                <div className="arrowright" onClick={scrollLeft}>
                    <img src={imagearrowright} alt="Scroll right" />
                </div>


                <div className="arrowleft" onClick={scrollRight}>
                    <img src={imagearrowleft} alt="Scroll left" />
                </div>

            </div>
        </div>
    );
}

export default Moments;
